export const navItems = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Services",
    link: "/services",
  },
  {
    id: 3,
    name: "Works",
    link: "/works",
  },
  {
    id: 4,
    name: "Team",
    link: "/team",
  },
  {
    id: 5,
    name: "Contact",
    link: "/contact",
  },
];